export function fuzzyMatch(term = "", strInput = "") {
  const regexPattern = ".*" + term.toLowerCase().split("").join(".*") + ".*"

  return new RegExp(regexPattern).test(strInput.toLowerCase())
}

export function wordsMatch(query = "", strInput = "") {
  let inputWords = strInput.toLowerCase().split(" ")

  return query
    .toLowerCase()
    .split(" ")
    .every((w) => inputWords.some((iw) => iw.includes(w)))
}
