import { reduceIntoKeyByValue } from "helpers/array"
import { Item } from "models/Item"

export interface VisualOrderElement {
  id: string
  item: string | null
  order: number
  parentElement: string | null
}

export default function visualOrderElementIds(
  itemIds: string[],
  elements: VisualOrderElement[]
): string[] {
  if (!itemIds || itemIds.length === 0 || !elements || elements.length === 0) {
    return []
  }
  let sortedELements = visualOrderElements(elements)

  let withUIOrder = itemIds.map((id) => {
    let element = elements.find((el) => el.item === id)

    if (element) {
      let found = element

      let sortedElement = sortedELements.find((el) => el.elementId === found.id)
      return { id: element.id, uiOrder: sortedElement?.uiOrder || 0 }
    }

    let pushNotFoundOrderToEndOfTheList = 5000

    return { id, uiOrder: pushNotFoundOrderToEndOfTheList }
  })

  let sortedByUIOrder = [...withUIOrder].sort((a, b) => a.uiOrder - b.uiOrder)

  return sortedByUIOrder.map(({ id }) => id)
}

export function sortItemsByVisualElementOrder(
  items: Array<{ id: string }>,
  elements: VisualOrderElement[]
) {
  const itemIds = items.map((item) => item.id)
  const itemsById = reduceIntoKeyByValue(items, "id")
  const elementsById = reduceIntoKeyByValue(elements, "id")
  const elementIds = visualOrderElementIds(itemIds, elements)
  return elementIds
    .map((id) => itemsById[elementsById[id]?.item || ""])
    .filter((i): i is Item => Boolean(i))
}

function applyOrderToChildren(
  parentId: string,
  elements: VisualOrderElement[],
  order: number,
  results: { elementId: string; uiOrder: number }[]
) {
  let children = elements
    .filter((e) => e.parentElement === parentId)
    .sort((a, b) => a.order - b.order)

  if (children.length === 0) return order

  children.forEach((child) => {
    results.push({ elementId: child.id, uiOrder: order })
    order++
    order = applyOrderToChildren(child.id, elements, order, results)
  })

  return order
}

export function visualOrderElements(
  elements: VisualOrderElement[]
): { elementId: string; uiOrder: number }[] {
  let rootELements = elements
    .filter((e) => !e.parentElement)
    .sort((a, b) => a.order - b.order)

  let results: { elementId: string; uiOrder: number }[] = []

  let order = rootELements[0]?.order || 0

  for (let el of rootELements) {
    results.push({ elementId: el.id, uiOrder: order })
    order++
    order = applyOrderToChildren(el.id, elements, order, results)
  }

  return results
}
